import type { GlobalChannel } from '../channels/channels';
import { Radio } from '../channels/radio';
import { ingredientsStore } from '../helpers/stores/ingredients-store';
import { fastDom } from './fastdom';
import { setupViewportEvents } from './setup-viewport-events';

export const activator = {
    async start(): Promise<void> {
        const globalChannel: GlobalChannel = Radio.channel('global');
        setupViewportEvents();
        setViewportHeight(globalChannel);
    },
};

function setViewportHeight(globalChannel: GlobalChannel) {
    fastDom.measure(() => window.innerHeight)
        .then(height => fastDom.mutate(() => {
            document.body.style.setProperty('--viewport-height', `${height}px`);
            ingredientsStore.setState({ viewportHeight: height });
        }))
        .catch(console.error);
    globalChannel.on('viewport:resize', dimensions => {
        document.body.style.setProperty('--viewport-height', `${dimensions.height}px`);
        ingredientsStore.setState({ viewportHeight: dimensions.height });
    });
}
