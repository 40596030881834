// custom typefaces
import '@fontsource/roboto-flex/variable.css';
// normalize CSS across browsers
import 'the-new-css-reset/css/reset.css';
// custom CSS styles
import './src/style.scss';

import Cookies from 'js-cookie';

import { activator } from './src/startup/activator';
import { waitForDomContentLoaded } from './src/startup/wait-for-dom-content-loaded';

waitForDomContentLoaded()
    .then(() => activator.start())
    .catch(err => console.error('Could not start app', err));

function decodeJwtResponse(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    if (!base64) {
        throw new Error('Invalid token provided!');
    }
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

window.googleLoginCallback = async (response) => {
    const responsePayload = decodeJwtResponse(response.credential);

    Cookies.set('g_state', responsePayload.sub, { expires: new Date(new Date().setHours(new Date().getHours() + 1)), secure: true, sameSite: 'strict' });
    Cookies.set('g_token', response.credential, { expires: new Date(new Date().setHours(new Date().getHours() + 1)), secure: true, sameSite: 'strict' });

    window.dispatchEvent(new CustomEvent('googleLoggedIn'));
};
