// @ts-expect-error no esmodule
import debounce from 'just-debounce-it';
// @ts-expect-error no esmodule
import throttle from 'just-throttle';

import type { GlobalChannel } from '../channels/channels';
import { Radio } from '../channels/radio';
import { fastDom } from './fastdom';

const gchannel: GlobalChannel = Radio.channel('global');

export function setupViewportEvents(windowWidth = getWindowWidth, windowHeight = getWindowHeight): void {
    const currentDimensions = { width: 0, height: 0 };
    fastDom.measure(() => Object.assign(currentDimensions, {
        width: windowWidth(),
        height: windowHeight(),
    })).catch(console.error);
    let idle = true;

    const scrollListener = throttle(() => {
        idle = false;
        gchannel.trigger('viewport:scroll');
        gchannel.trigger('viewport:change');
    }, 100);

    window.addEventListener('scroll', scrollListener, true);

    const resizeListener = debounce(() => {
        fastDom.measure(() => ({
            width: windowWidth(),
            height: windowHeight(),
        })).then(newDimensions => fastDom.mutate(() => {
            // because of the bug in IOS (The resize event fires randomly while scrolling)
            if (currentDimensions.width !== newDimensions.width || currentDimensions.height !== newDimensions.height) {
                gchannel.trigger('viewport:resize', newDimensions);
                gchannel.trigger('viewport:change');
                Object.assign(currentDimensions, newDimensions);
            }
        })).catch(console.error);
    }, 200);

    window.addEventListener('resize', resizeListener);

    gchannel.reply('viewport:idle', () => idle);
    gchannel.on('viewport:change', debounce(() => {
        idle = true;
        gchannel.trigger('viewport:idle');
    }, 150));
}

function getWindowWidth() {
    return window.innerWidth;
}

function getWindowHeight() {
    return window.innerHeight;
}
