import type { Response } from './channel-response';
import type { Channel } from './channels';

interface Listener {
    callback?: (event: any) => void;
    context?: any;
}

interface Reply {
    context?: unknown;
    response: Response<any>;
}

export interface Delegate {
    handleEvent(eventName: string, ...args: any): void;

    handleRequest(requestName: string): any;
}

function off(listeners: Record<string, Listener[] | undefined>, eventName: string, callback?: (event: any) => void, context?: any) {
    const listenersToDeregister = listeners[eventName];

    if (!listenersToDeregister) {
        return;
    }

    listeners[eventName] = listenersToDeregister.filter(listener => {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        return callback && callback !== listener.callback || context && context !== listener.context;
    });
}

function offAll(listeners: Record<string, Listener[] | undefined>, callback?: (event: any) => void, context?: any) {
    Object.keys(listeners).forEach(eventName => off(listeners, eventName, callback, context));
}

export default class BlogChannel implements Channel<Record<string, any>, Record<string, any>> {
    private delegate: Delegate | null;
    private listeners: Record<string, Listener[] | undefined> = {};
    private replies: Record<string, Reply[] | undefined> = {};

    constructor(private readonly name: string, delegate: Delegate) {
        this.delegate = delegate;
    }

    public setDelegate(newDelegate: Delegate | null): void {
        this.delegate = newDelegate;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public trigger(eventNames: string, ...args: any): void {
        this.delegate?.handleEvent(eventNames, ...args);
    }

    public on(eventNames: string, callback?: (event: any) => unknown, context?: unknown): any {
        eventNames.split(/\s+/).forEach(eventName => {
            if (!this.listeners[eventName]) {
                this.listeners[eventName] = [];
            }
            (this.listeners[eventName] as Listener[]).push({ callback, context });
        });
    }

    public off(eventName?: string, callback?: (event: any) => void, context?: unknown): any {
        eventName ? off(this.listeners, eventName, callback, context) : offAll(this.listeners, callback, context);
    }

    public once(events: string, callback: (event: any) => void, context?: unknown): any {
        const onceCallback = (...args: any) => {
            this.off(events, onceCallback);
            callback.apply(context, args);
        };
        this.on(events, onceCallback);
    }

    public sendEvent(eventNames: string, ...args: any[]): void {
        eventNames.split(/\s+/).forEach(eventName => {
            const events = this.listeners[eventName];
            events?.forEach(listener => listener.callback?.apply(listener.context, args as any));
        });
    }

    public request(requestName: string): any {
        return this.delegate ? this.delegate.handleRequest(requestName) : undefined;
    }

    public reply(requestName: string, response: Response<any>, context?: unknown): any {
        if (!this.replies[requestName]) {
            this.replies[requestName] = [];
        }
        (this.replies[requestName] as Reply[]).push({ context, response });
    }

    public repliesForName(requestName: string): Response<any> {
        const repliesList = this.replies[requestName];
        return repliesList?.[repliesList.length - 1]?.response;
    }

    public stopReplying(requestName?: string, context?: unknown): void {
        if (requestName) {
            if (!context) {
                delete this.replies[requestName];
            }
            this.replies[requestName] = this.replies[requestName]?.filter(c => c.context !== context);
        } else {
            this.replies = {};
        }
    }

    public reset(): void {
        this.stopReplying();
        this.off();
    }

    public destroy(): void {
        this.reset();
        this.delegate = null;
    }
}
