import BlogChannel from './channel';
import type { Response } from './channel-response';
import { getResponseValue } from './channel-response';
import type { GlobalChannel } from './channels';

export interface GlobalRadio {
    _internalChannel(): BlogChannel;

    channel(name: 'global'): GlobalChannel;

    registerTriggerObserver(observer: TriggerObserver): void;

    deregisterTriggerObserver(observer: TriggerObserver): void;

    registerRequestProvider(provider: RequestProvider): void;

    deregisterRequestProvider(provider: RequestProvider): void;

    reset(): void;
}

export type TriggerObserver = (eventName: string, ...args: any[]) => void;
export type RequestProvider = (requestName: string) => Response<unknown>;

const triggerObservers: Set<TriggerObserver> = new Set<TriggerObserver>();
const requestProviders: Set<RequestProvider> = new Set<RequestProvider>();

let globalChannel: BlogChannel | undefined;

export const Radio: GlobalRadio = {
    _internalChannel: () => {
        if (!globalChannel) {
            globalChannel = new BlogChannel('Radio.global', {
                handleEvent(eventNames, ...args): void {
                    globalChannel?.sendEvent(eventNames, ...args);
                    triggerObservers.forEach(o => o(eventNames, ...args));
                },
                handleRequest(requestName): any {
                    const allProviders = [
                        (r: string) => globalChannel?.repliesForName(r),
                        ...requestProviders.values(),
                    ];
                    for (const provider of allProviders) {
                        const response = provider(requestName);
                        if (response) {
                            return getResponseValue(response);
                        }
                    }
                    return undefined;
                },
            });
            globalChannel.setDelegate = () => {
                throw new Error('not allowed');
            };
        }
        return globalChannel;
    },
    channel() {
        return Radio._internalChannel() as GlobalChannel;
    },
    registerTriggerObserver(observer) {
        triggerObservers.add(observer);
    },
    deregisterTriggerObserver(observer) {
        triggerObservers.delete(observer);
    },
    registerRequestProvider(provider) {
        requestProviders.add(provider);
    },
    deregisterRequestProvider(provider) {
        requestProviders.delete(provider);
    },
    reset() {
        this.channel('global').reset();
        triggerObservers.forEach(this.deregisterTriggerObserver);
        requestProviders.forEach(this.deregisterRequestProvider);
    },
};
