const domLoaded = new Promise<void>(resolve => {
    if (document.readyState !== 'loading') {
        resolve();
    } else {
        document.addEventListener('DOMContentLoaded', () => {
            resolve();
        });
    }
});

export function waitForDomContentLoaded(): Promise<void> {
    return domLoaded;
}
