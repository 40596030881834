import { create } from 'zustand';

export type IngredientsStoreState = {
    viewportHeight: number;
    ingredientsOverlayShown: boolean;
    portions?: number;
}

export const ingredientsStore = create<IngredientsStoreState>(() => ({
    viewportHeight: 0,
    ingredientsOverlayShown: false,
    portions: undefined,
}));
